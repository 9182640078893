@use 'sass:color';

$border-radius: 2px;
$primary: #558955;
$secondary: #939393;
$info: #6a9ba3;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$body-text: #7f7f7f;
$body-bg: #ffffff;
$headings-text: #242424;
$subtle-text: #7a7b7d;
$disabled-text: #8f8f8f;
$component-text: #7f7f7f;
$component-bg: #ffffff;
$base-text: #7f7f7f;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ececec;
$selected-text: #ffffff;
$selected-bg: #6a9ba3;
$kendo-button-text: #424242;
$kendo-button-bg: #f5f5f5;
$link-text: #558955;
$link-hover-text: #7a7b7d;
$series-a: #ff6358;
$series-b: #ffe162;
$series-c: #4cd180;
$series-d: #4b5ffa;
$series-e: #ac58ff;
$series-f: #ff5892;
$k-button-md: #6a9ba3;
$light: #ffffff;

$kendo-button-border-radius: 0.25rem;
$kendo-button-font-family: 'Exo 2', sans-serif;
$btn-padding-y: 0.75rem;
$btn-padding-x: 2rem;
$grid-header-bg: #e9f0e9;
$grid-header-text: #{$primary};
$grid-footer-bg: #{$grid-header-bg};
$grid-border: #{$primary};
$grid-alt-bg: unset;
$grid-hovered-bg: unset;

// Bootstrap overrides
$body-color: #7f7f7f;
$headings-font-family: 'Exo 2', sans-serif;

@import '~@progress/kendo-theme-bootstrap/dist/all.scss';
@import '~bootstrap/scss/bootstrap.scss';

// These variables will be available throughout the application after SASS compilation
:root {
  --geosap-primary-color: #{$primary};
  --geosap-secondary-color: #{$secondary};
  --geosap-input-border-color: #aac4aa;
  --geosap-input-focus-shadow: #{$kendo-input-focus-shadow};
  --geosap-disabled: #{$disabled-text};
  --geosap-navbar-color: #4a4a4a;
  --geosap-navbar-width: 110px;
  --geosap-navbar-icon-color: #{$light};
  --geosap-navbar-selected-item-color: #3c3c3c;
  --geosap-viewer-layers-width: 240px;
  --geosap-viewer-toolbar-width: 80px;
  --geosap-viewer-toolbar-icon-width: 22px;
  --geosap-profile-icon-color: #e0e0e0;
  --geosap-card-bg-color: #f2f2f2;
  --geosap-light: #ffffff;
  --geosap-steps-background: rgba(169, 169, 169, 0.75);
  --geosap-link-hover-color: #{$link-hover-color};
  --geosap-k-button-md: #{$k-button-md};
  --geosap-body-text: #{$body-text};
  --geosap-sapflow-card-border-color: rgba(127, 127, 127, 0.21);
  --geosap-process-number-background: rgba(242, 242, 242, 0.5);
  --geosap-error: #{$error};
  --geosap-error-link-hover: #{color.scale($error, $alpha: -40%)};
  --geosap-grid-header-bg: #{$grid-header-bg};
  --geosap-selected: #558955;
  --geosap-kendo-button-bg: #{$kendo-button-bg};
  --geosap-sapflow-details-selected-button-bg: #d2dee0;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

/*KENDO CUSTOMIZATION*/
.k-window {
  border-radius: 20px;
  max-width: 600px;
}

#potree_description h3 {
  line-height: normal !important;
}

.k-input-inner {
  padding: 0 0.75rem !important;
}

.k-input-button {
  padding: 0 0.25rem !important;
}

.k-slider-selection {
  background-color: transparent !important;
}

.k-button-sm {
  padding: 0.25rem 0.25rem !important;
}

.k-draghandle {
  border-radius: 0 !important;
  border-color: rgb(112, 112, 112) !important;
  width: 6px !important;
  height: 20px !important;
  background-color: rgb(157, 157, 157) !important;
}

.k-slider-track {
  border-radius: 0 !important;
}

.k-grid tbody tr:not(.k-detail-row):hover,
.k-grid tbody tr:not(.k-detail-row).k-state-hover {
  background-color: #ededed;
}

.k-pager-first,
.k-pager-last {
  display: none;
}

/*GEOSAP CSS*/
/*checked checkbox*/
.layers-checkbox .k-checkbox:checked {
  background-color: $secondary;
  color: white;
  border-color: $secondary;
}

.link-disabled {
  pointer-events: none;
  color: rgb(112, 112, 112) !important;
  text-decoration: none !important;
}

.animated-transform {
  -webkit-transition: -webkit-transform 0.15s ease-in-out;
  -ms-transition: -ms-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
}

.animated-opacity {
  transition: opacity 0.15s ease-in-out;
}

.animated-background {
  transition: background-color 0.15s ease-in-out;
}

.cesium-widget-credits {
  display: none !important;
}
